import {Grid, Typography} from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings'

const currYear = new Date().getFullYear();


function App() {
    return (
        <div>
            <header>
                <img src="account-portal-logo.png" alt="NextGear Capital Account Portal"/>
            </header>
            <div className="maint-body">
                <SettingsIcon className="spin"/>
                <Grid container={true} spacing={0}>
                    <Grid item={true} xs={true} lg={true} xl={true}/>
                    <Grid item={true} xs={10} lg={8} xl={6}>
                        <Typography
                            align="center"
                            variant="h6"
                            color="inherit"
                            gutterBottom={true}
                        >
                            Account Portal is currently unavailable.
                        </Typography>
                        <Typography variant="body2">
                            We apologize for the inconvenience and appreciate your patience as we
                            work through this matter. If you require immediate attention, please
                            contact us at{' '}
                            <a href="mailto:ngc.customerservice@coxautoinc.com">
                                ngc.customerservice@coxautoinc.com
                            </a>.
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={true} lg={true} xl={true}/>
                </Grid>
            </div>
            <footer>
                <div>
                    <a href="https://www.coxautoinc.com/"target="_blank" rel="noreferrer">
                        <img src="cox-logo.png" alt="A Cox Automotive Brand"/>
                    </a>
                </div>
                <ul>
                    <li>Copyright 2013-{currYear}. NextGear Capital, Inc.</li>
                    <li>
                        <a
                            href="https://www.nextgearcapital.com/legal/privacy-policy/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy Statement
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.nextgearcapital.com/contact/contact-us/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Contact Us
                        </a>
                    </li>
                </ul>
            </footer>
        </div>
    );
}

export default App;
